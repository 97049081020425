import React, { useCallback, useRef, useState } from "react";
import IntroText from "./intro-text";
import LandingFold from "./landing-fold";
import { animated, useSpring, useSpringValue } from "@react-spring/web";
// @ts-ignore
import DownArrowSvg from "/src/assets/svgs/down-arrow.svg";

const tomImageUrl = new URL("/src/assets/images/tom.png", import.meta.url);

type Props = {
  initialPath: string;
};

export default function Main({ initialPath }: Props) {
  const [introTextComplete, setIntroTextComplete] = useState(false);
  const [introTextSpring, introTextSpringApi] = useSpring(() => ({
    from: { opacity: 1, y: 80 },
  }));

  const [landingFoldSpring, landingFoldSpringApi] = useSpring(() => ({
    from: { opacity: 0, y: 80 },
  }));

  const enterLandingFold = useCallback(() => {
    landingFoldSpringApi.start({
      to: { opacity: 1, y: 0 },
      config: { mass: 1, tension: 80, friction: 20 },
    });
    setIntroTextComplete(true);
  }, []);

  React.useEffect(() => {
    enterLandingFold();
  }, []);

  const onIntroTextAnimationEnd = useCallback(() => {
    introTextSpringApi.start({
      to: { opacity: 0, y: 0 },
      config: { mass: 1, tension: 80, friction: 20 },
      onRest: enterLandingFold,
    });
  }, [enterLandingFold]);

  const scrollArrowSpring = useSpring({
    from: { y: 12 },
    to: async (next) => {
      while (true) {
        await next({ y: 0 });
        await new Promise((r) => setTimeout(r, 320));
        await next({ y: 12 });
        await new Promise((r) => setTimeout(r, 320));
      }
    },
    config: { mass: 3, tension: 400, friction: 40 },
  });
  const scrollOpacitySpring = useSpringValue(1);

  const onInitialScroll = useCallback(() => {
    scrollOpacitySpring.start({
      to: 0,
      config: { duration: 200 },
    });
  }, []);

  return (
    <div className="flex flex-col max-w-[1200px] px-5 sm:px-10 mx-auto py-[100px] gap-40 h-auto">
      {/*<animated.div
        className="fixed top-[100px] left-0 right-0 h-auto flex"
        style={{
          ...introTextSpring,
          visibility: introTextSpring.opacity.to((o) =>
            o === 0 ? "hidden" : "visible"
          ),
        }}
      >
        <div className="flex flex-col max-w-[1200px] px-5 sm:px-10 mx-auto">
          <IntroText
            onInitialScroll={onInitialScroll}
            onTextAnimationEnd={onIntroTextAnimationEnd}
          />
        </div>
      </animated.div>*/}
      <animated.div
        className="fixed top-0 right-0 left-0 h-full overflow-auto flex flex-col no-scrollbar"
        style={{
          ...landingFoldSpring,
        }}
      >
        <div className="flex flex-col items-center max-w-[1200px] px-5 sm:px-10 mx-auto pt-[180px] pb-[100px] w-full gap-[200px]">
          <LandingFold visible={introTextComplete} initialPath={initialPath} showDownload={initialPath === 'mobile'} />
          {/* <img
            src={tomImageUrl.toString()}
            alt="Tom Cruise"
            className="w-[125px] h-[100px]"
          /> */}
        </div>
      </animated.div>
      {/* <animated.div
        className="fixed bottom-12 w-full left-0 flex justify-center pointer-events-none"
        style={{ ...scrollArrowSpring, opacity: scrollOpacitySpring }}
      >
        <DownArrowSvg />
      </animated.div> */}
    </div>
  );
}
