import React, { useEffect, useMemo } from "react";
// @ts-ignore
import Avatar1Svg from "/src/assets/svgs/avatar-1.svg";
// @ts-ignore
import Avatar2Svg from "/src/assets/svgs/avatar-2.svg";
// @ts-ignore
import Avatar3Svg from "/src/assets/svgs/avatar-3.svg";
// @ts-ignore
import Avatar4Svg from "/src/assets/svgs/avatar-4.svg";
// @ts-ignore
import Avatar5Svg from "/src/assets/svgs/avatar-5.svg";
// @ts-ignore
import AvatarCheckEmailSvg from "/src/assets/svgs/avatar-check-email.svg";
// @ts-ignore
import AppStoreSvg from "/src/assets/svgs/app-store.svg";
// @ts-ignore
import PlayStoreSvg from "/src/assets/svgs/play-store.svg";
import classNames from "classnames";
import { animated, to, useSpring, useSpringValue } from "@react-spring/web";
import Color from "colorjs.io";
import { useIntersectionObserver } from "usehooks-ts";
import { PopupButton } from "@typeform/embed-react";

import validateEmail from "../../helpers/validate-email";

const iphoneImageUrl = new URL(
  "/src/assets/images/iphone-11.png",
  import.meta.url
);

const landingPageVideoUrl = new URL(
  "/src/assets/videos/landing-page-video.mp4",
  import.meta.url
);

const SEND_BUTTON_COLORS = [
  "#F886A6",
  "#B39DDB",
  "#90CAF9",
  "#A5D6A7",
  "#FFAB91",
];

function changeAlpha(c: string, alpha: number) {
  const color = new Color(c);
  color.alpha = alpha;
  return color.toString({ format: "rgba" });
}

// Default typeform id until we set the env param on vercel.
const waitlistTypeformId = process.env.WAITLIST_TYPEFORM_ID || "ttPXF3Xd";
if (!waitlistTypeformId) {
  throw new Error("WAITLIST_TYPEFORM_ID not set");
}

async function addEmailToWaitlist(email: string) {
  const emailCaptureUrl = process.env.EMAIL_CAPTURE_URL;
  if (!emailCaptureUrl) {
    console.warn("EMAIL_CAPTURE_URL not set");
    return;
  }
  try {
    const response = await fetch(emailCaptureUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        email,
      }),
    });
    if (!response.ok) {
      console.error("Failed to add email to waitlist");
      // throw new Error("Failed to add email to waitlist");
    }
  } catch (e) {
    console.error(e);
  }
}

type Props = {
  visible: boolean;
  initialPath: string;
  showDownload?: boolean;
};

export default function LandingFold({ visible, initialPath, showDownload }: Props) {
  //console.log("LandingFold", visible);
  const sendColorSpring = useSpring({
    from: {
      color: SEND_BUTTON_COLORS[0],
    },
    to: async (next) => {
      while (true) {
        for (let i = 0; i < SEND_BUTTON_COLORS.length; i++) {
          await next({
            color: SEND_BUTTON_COLORS[i],
          });
        }
      }
    },
    config: { duration: 1000 },
  });

  const waitlistButtonSpring = useSpring({
    from: {
      backgroundColor: SEND_BUTTON_COLORS[0],
    },
    to: async (next) => {
      while (true) {
        for (let i = 0; i < SEND_BUTTON_COLORS.length; i++) {
          await next({
            backgroundColor: SEND_BUTTON_COLORS[i],
          });
        }
      }
    },
    config: { duration: 1000 },
  });

  const [isInputFocused, setIsInputFocused] = React.useState(false);

  const inputFocusAlphaSpring = useSpring({
    to: {
      value: isInputFocused ? 0.12 : 0.08,
    },
    config: { duration: 200 },
  });

  const [email, setEmail] = React.useState("");
  const emailSubmitAnimationProgress = useSpringValue(0);

  const emailValidationSpring = useSpringValue(0);

  const inputContainerStyle = {
    y: emailSubmitAnimationProgress.to((p) => -p * 110),
    rotate: emailSubmitAnimationProgress.to((p) => -p * 15),
    opacity: emailSubmitAnimationProgress.to((p) => 1 - p),
    x: emailValidationSpring,
  };

  const confirmationToastStyle = {
    y: emailSubmitAnimationProgress.to((p) => (1 - p) * 80),
    rotate: emailSubmitAnimationProgress.to((p) => (1 - p) * 15),
    opacity: emailSubmitAnimationProgress,
  };

  const animateEmailConfirmation = () => {
    emailSubmitAnimationProgress.start({
      to: async (next) => {
        await next(1, { config: { mass: 1, tension: 300, friction: 20 } });
        await new Promise((r) => setTimeout(r, 4800));
        await next(0, { config: { mass: 1, tension: 300, friction: 20 } });
      },
    });
  };

  const submitEmail = () => {
    let sanitizedEmail = email.trim();
    if (sanitizedEmail === "" || !validateEmail(sanitizedEmail)) {
      emailValidationSpring.start({
        from: -15,
        to: 0,
        config: { mass: 1, tension: 4000, friction: 20 },
      });
      return;
    }
    addEmailToWaitlist(sanitizedEmail);
    setEmail("");
    animateEmailConfirmation();
  };

  const { isIntersecting, ref: videoRefLegacy } = useIntersectionObserver({
    threshold: 0.6,
    freezeOnceVisible: true,
  });

  const videoRef = React.useRef<HTMLVideoElement | undefined>(undefined);

  useEffect(() => {
    if (isIntersecting && visible) {
      const video = videoRef.current;
      video?.play();
    }
  }, [isIntersecting, visible]);

  const trackingParams = useMemo(() => {
    const params = {
      "typeform-welcome": 0,
    };
    if (initialPath) {
      params["hassource"] = 1;
      params["utm_source"] = initialPath;
    } else {
      params["utm_source"] = "agenthome";
    }
    return params;
  }, [initialPath]);

  return (
    <div className="flex flex-col items-center gap-[120px] w-full">
      <div className="flex flex-col items-center gap-10 w-full">
        <div className="flex gap-4">
          <Avatar1Svg className="w-[32px] h-[32px] sm:w-[48px] sm:h-[48px]" />
          <Avatar2Svg className="w-[32px] h-[32px] sm:w-[48px] sm:h-[48px]" />
          <Avatar3Svg className="w-[32px] h-[32px] sm:w-[48px] sm:h-[48px]" />
          <Avatar4Svg className="w-[32px] h-[32px] sm:w-[48px] sm:h-[48px]" />
          <Avatar5Svg className="w-[40px] h-[32px] sm:w-[57px] sm:h-[46px]" />
        </div>
        <div className="text-foreground font-satoshi font-bold text-[52px] sm:text-[102px] lg:text-[112px] leading-[1.1] lg:leading-[1.2] text-center">
          {
            showDownload ? 'Download Cedar' : 'Introducing Cedar'
          }
        </div>
        {
          showDownload ? null : (
            <div className="text-foreground font-satoshi font-normal text-[24px] sm:text-[32px] leading-[1.2] text-center opacity-60">              
              The world's first AI Career Agent!
            </div>  
          )
        }
      </div>
      <div className="flex flex-col gap-8 w-full">
        <div className="flex flex-col gap-3">
          {
            showDownload ? (
              <div className="flex gap-2 w-full sm:w-auto justify-center md:scale-x-150 md:scale-y-150">
                <a href="https://apps.apple.com/ca/app/commit-ai-talent-agent/id6472682084" target="_blank">
                  <AppStoreSvg className="cursor-pointer  " />
                </a>
                <a href="https://play.google.com/store/apps/details?id=dev.commit.app" target="_blank">
                  <PlayStoreSvg className="cursor-pointer" />
                </a>
              </div>
            ) : (
              <>
                <PopupButton
                  className="self-center"
                  id={waitlistTypeformId}
                  tracking={trackingParams}
                >
                  <animated.div
                    className="self-center flex text-[#101010] justify-center rounded-3xl font-bold py-3 px-10 hover:opacity-80 transition-opacity duration-300"
                    style={waitlistButtonSpring}
                  >
                    Join the waitlist
                  </animated.div>
                </PopupButton>
                <div className="text-foreground font-satoshi font-normal text-[12px] sm:text-[14px] leading-[1.2] text-center text-wrap">
                  We give access to groups daily
                </div>
              </>
            )
          }
        </div>
        {/* <div className="text-foreground font-satoshi font-normal text-[16px] sm:text-[24px] leading-[1.2] text-center text-wrap">
          Join the waitlist today - we give access to groups daily
        </div> */}
        {/* <div className="w-full flex flex-col md:flex-row gap-4 md:gap-10 items-center md:justify-center">
          <div className="relative w-full max-w-[360px] md:w-[360px] flex-1 sm:flex-none">
            <animated.div className="relative" style={inputContainerStyle}>
              <animated.input
                type="text"
                placeholder="Enter email address"
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => setIsInputFocused(false)}
                className={classNames(
                  "focus:outline-none focus:ring-0",
                  "rounded-3xl h-[58px] w-full pl-4 pr-[56px]",
                  "text-foreground placeholder:text-foreground/60 font-satoshi font-medium text-[16px] leading-[1.32]"
                )}
                style={{
                  backgroundColor: to(
                    [
                      inputFocusAlphaSpring.value,
                      sendColorSpring.color,
                    ] as const,
                    (inputFocusAlpha, color) => {
                      return changeAlpha(color, inputFocusAlpha);
                    }
                  ),
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    submitEmail();
                  }
                }}
              />
              <svg
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
                className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
                onClick={submitEmail}
              >
                <animated.rect
                  x="0.800171"
                  width="32"
                  height="32"
                  rx="16"
                  fill={sendColorSpring.color}
                />
                <path
                  d="M16.8002 21.3333L22.1335 16M22.1335 16L16.8002 10.6666M22.1335 16H11.4669"
                  stroke="#101010"
                  strokeWidth="2.13333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </animated.div>
            <animated.div
              className="absolute top-0 left-0 h-full w-full flex items-center gap-8"
              style={confirmationToastStyle}
            >
              <AvatarCheckEmailSvg />
              <div className="text-foreground font-reckless font-light text-[34px] leading-[1.2]">
                You’re on the list
              </div>
            </animated.div>
          </div>

          { <div className="flex gap-2 w-full sm:w-auto justify-center">
            <AppStoreSvg className="cursor-pointer" />
            <PlayStoreSvg className="cursor-pointer" />
          </div>}
        </div> */}
        {
          showDownload ? null : (
            <div
              className="relative mx-auto"
              style={{
                width: 350,
                height: 690,
                background: `url(${iphoneImageUrl.toString()}) no-repeat center center`,
                backgroundSize: "350px 690px",
              }}
            >
              <video
                ref={(ref) => {
                  videoRefLegacy(ref);
                  videoRef.current = ref ?? undefined;
                }}
                className="absolute"
                style={{
                  left: `26px`,
                  top: `23px`,
                  width: `298px`,
                  height: `643px`,
                  borderRadius: 40,
                  objectFit: "fill",
                }}
                src={`${landingPageVideoUrl.toString()}#t=0.1`}
                loop
                muted
                playsInline
                preload="auto"
                controls={false}
              />
            </div>
          )
        }
      </div>
    </div>
  );
}
