import React from "react";
import { useLocation } from "react-router-dom";
import Main from "./main";
import { useEffect, useState } from "react";

export default function Root() {
  const location = useLocation();
  const [path, setPath] = useState("");

  useEffect(() => {
    const path = location.pathname.split("/").pop() || "";
    setPath(path);
    if (path !== 'mobile') {
      window.history.replaceState({}, "", "/");
    }
  }, [location]);

  return <Main initialPath={path} />;
}
