import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Main from "./routes/main";
import Root from "./routes/root";

// Clear the existing HTML content
document.body.innerHTML = '<div id="app" class="h-full"></div>';

// Render your React component instead
const root = createRoot(document.getElementById("app"));
root.render(
  <StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Root />} />
        {/* <Route path="/" element={<Main />} /> */}
        {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
      </Routes>
    </BrowserRouter>
  </StrictMode>
);

if ("scrollRestoration" in history) {
  // Back off, browser, I got this...
  history.scrollRestoration = "manual";
}
